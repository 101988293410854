<template>


 <section>

      <div class="container cont">



           <div v-if="this.subtotal!='0.00'">

            <small class="form-text text-muted" style="font-family: Quicksand, sans-serif;">Paso 3 de 3</small>
            <h2 v-if="this.subtotal!='0.00' && event.id!=83" class="text-justify va-h6" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Pago</strong></h2>
             

                </div>


              <div v-if="this.subtotal!='0.00'" class="row rw" style="text-align:center;">

              

              <table v-if="proc==1" class="table" style="padding-top: 20px;">
                                                    <thead>
                                                        <tr>
                                                            <th style="color: rgb(255,255,255);">Subtotal</th>
                                                            <th style="color: rgb(255,255,255);">Fee</th>
                                                            <th style="color: rgb(255,255,255);">Final</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style="color: white;">
                                                            <td>${{subtotal}}</td>
                                                            <td>${{fee}}</td>
                                                            <td>${{total}}</td>
                                                        </tr>
                                                    </tbody>
                </table>



             

              <table v-if="proc==3" class="table" style="padding-top: 20px;">
                                                    <thead>
                                                        <tr>
                                                            <th style="color: rgb(255,255,255);">Subtotal</th>
                                                            <th style="color: rgb(255,255,255);">Fee</th>
                                                            <th style="color: rgb(255,255,255);">Final</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style="color: white;">
                                                            <td>${{subtotal}}</td>
                                                            <td>${{newFee}}</td>
                                                            <td>${{newTotal}}</td>
                                                        </tr>
                                                    </tbody>
              </table>



              
              
              
              <p style="color: white; padding-bottom: 10px;">Selecciona a continuación tú forma de pago preferida. Si el sistema pide código postal ingresa 170105.</p>

              
               <div v-if="bankAccount && loadingMain==false" style="padding-top: 10px;">
                              <BankAccount :event="event.id" :newTotal="newTotal" :total="total" :subtotal="subtotal"  @isSpecialCal="specialCal()"  @isSpecialProm="proc=2" @returnMessageFromBankAccount="returnFromBank()" @messageFromBankAccount="startSaleBankCase" /> 
                            
              </div>


              <div v-if="bankAccount1Ec && loadingMain==false" style="padding-top: 10px;">
                              <BankAccount1Ec :event="event.id" :newTotal="newTotal" :total="total" :subtotal="subtotal"  @isSpecialCal1Ec="specialCal1Ec()"  @isSpecialProm="proc=2" @returnMessageFromBankAccount="returnFromBank1Ec()" @messageFromBankAccount="startSaleBankCase" /> 
                            
              </div>





              <div v-if="posAccount && loadingMain==false" style="padding-top: 10px;">
                              <PosAccount :event="event.id" :total="total" :subtotal="subtotal"  @isSpecialProm="proc=2" @returnMessageFromPosAccount="returnFromPos()" @messageFromPosAccount="startSalePosCase" /> 
                            
              </div>




              <div v-else-if="cryptoAccount && loadingMain==false" style="padding-top: 10px;">
                              <CryptoAccount :total="total" @returnMessageFromBankAccount="cryptoAccount=false" @messageFromBankAccount="startSaleBankCase" /> 
                              
                               

              </div>


              <div v-else-if="nuveiAccount && loadingMain==false" style="padding-top: 10px;">
                              <VueNuvei :reference="nuvei_reference" @returnMessageFromNuvei="returnFromNuvei()" @messageFromNuvei="startSaleNuvei" /> 
                              
            
              </div>


              

              <div style="padding-bottom: 30px;" v-show="showMethods">
              
              
              <div  v-if="loadingMain==false">




              <div v-if="showcash" class="form-group fmbt" style="padding-bottom: 1px; "><button  @click="CashCase()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fas fa-university"></i> Efectivo</button></div>                 

              <div v-if="showpos" class="form-group fmbt" style="padding-bottom: 1px;  padding-top: 5px; margin-top: 25px;"><button  @click="posAccountCase()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fab fa-bitcoin"></i> Tarjeta POS</button></div>  

              <div v-if="showbank1ec" class="form-group fmbt" style="padding-bottom: 1px;"><button  @click="bankAccountCase1Ec()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fas fa-university"></i> Transferencia Bancaria ($1 Fee)</button></div>  

              <div v-if="showbank" class="form-group fmbt" style="padding-bottom: 1px;  "><button  @click="bankAccountCase()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fas fa-university"></i> Transferencia Bancaria</button></div>  
              
              <div v-if="showPagoPlux" class="form-group fmbt" style="padding-bottom: 1px; padding-top: 5px; margin-top: 25px;"><VuePagoPlux :email="this.customer_prop.email" :pname="this.pname" :address="this.f_address"  :reload="this.reload"  :phone="this.f_phone" :total="this.total" :event="this.event.id" @reload="reloadCasePp()" @messageFromPagoPlux="startSalePagoPlux"  style="display: flex; justify-content: center;"/>  </div>  


              <div v-if="showMpAr" class="form-group fmbt" style="padding-bottom: 1px; padding-top: 5px; margin-top: 25px;"><VueMp @messageFromMp="startSaleTwoStep()" :cartTotal="this.total" :reference="mp_reference"/>  </div>  

              <div v-if="showPagoPluxAR" class="form-group fmbt" style="padding-bottom: 1px; padding-top: 5px; margin-top: 25px;"><VuePagoPluxAR :email="this.customer_prop.email" :pname="this.pname" :address="this.f_address"  :reload="this.reload"  :phone="this.f_phone" :total="this.total" :event="this.event.id" :conversion_rate="this.conversion_rate" @reload="reloadCasePp()" @messageFromPagoPlux="startSaleTwoStep"  style="display: flex; justify-content: center;"/>  </div>  
              
             
              <div v-if="event.id==1" class="form-group fmbt" style="padding-bottom: 1px; padding-top: 5px; margin-top: 25px;"><VueDataFast :email="this.customer_prop.email" phone="997668666" :total="this.total" @messageFromPagoPlux="startSalePagoPlux"  style="display: flex; justify-content: center;"/>  </div>  


              <div v-if="event.id==10" class="form-group fmbt" style="padding-bottom: 1px;  padding-top: 5px; margin-top: 25px;"><button  @click="cryptoCase()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fab fa-bitcoin"></i> Criptomonedas</button></div>  

              <div  v-if="event.id==12" class="form-group fmbt" style="padding-bottom: 1px;  padding-top: 5px; margin-top: 25px;"><button  @click="nuveiCase()" class="btn btn-primary btn-block btn-lg btn-bank" type="submit" style="font-family: Quicksand, sans-serif; padding-bottom: 15px;  margin-bottom: 15px; ">  <i style="color: #ffffff;" class="fab fa-bitcoin"></i> Tarjeta de Crédito / Débito</button></div>  

              
              <div v-if="showPaypal" class="form-group fmbt"><VueEx :cartTotal="this.total" @messageFromPaypal="startSalePaypalCase()"  style="display: flex; justify-content: center;"/>  </div>  


              <div v-if="event.id==12" class="form-group fmbt"><VueExDemo :cartTotal="this.total" @messageFromPaypal="startSalePaypalCase()"  style="display: flex; justify-content: center;"/>  </div>  
              
              <div v-if="showPaypal" class="form-group fmbt"><VueEx :cartTotal="this.total" @messageFromPaypal="startSalePaypalCase()"  style="display: flex; justify-content: center;"/>  </div>  

              <div v-if="showPaypalAR" class="form-group fmbt"><VueEx :cartTotal="this.totalAR" @messageFromPaypal="startSalePaypalCaseAR()"  style="display: flex; justify-content: center;"/>  </div>  

            </div>

              </div>
              
           </div>
      </div>

         <div>

     <div v-if="ticketsApp==true">
     
    <PdfGeneration  @messageFromPdfGeneration="afterPdf" :event="event" :ticketsTable="ticketsM" :invoice="invoice" />  
   </div>




   <div v-if="loadingMain==true">  
    <SpinnerSec />  
   </div>


           

   </div>

  </section>

  

    
</template>


<script>
import { apiService } from "../../common/api.services.js";
import { apiServiceForm } from "../../common/api.services.js";



import VueEx from "@/components/sales/VueEx.vue";
import VueExDemo from "@/components/sales/VueExDemo.vue";
import VueNuvei from "@/components/sales/VueNuvei.vue";
import BankAccount from "@/components/sales/BankAccount.vue";
import BankAccount1Ec from "@/components/sales/BankAccount1Ec.vue";

import PdfGeneration from "@/components/sales/PdfGeneration.vue";
import SpinnerSec from "@/components/sales/SpinnerSec.vue";
import CryptoAccount from "@/components/sales/CryptoAccount.vue";
import PosAccount from "@/components/sales/PosAccount.vue";

import VuePagoPlux from "@/components/sales/VuePagoPlux.vue";

import VueDataFast from "@/components/sales/VueDataFast.vue";

import VueMp from "@/components/sales/VueMp.vue";

import VuePagoPluxAR from "@/components/sales/VuePagoPluxAR.vue";







const data = new FormData()

export default {
  
    name: 'CustomerData',

     components: {
  VueExDemo,
   VueEx,
   BankAccount,
    PdfGeneration,
    SpinnerSec,
    CryptoAccount,
    VueNuvei,
    PosAccount,
    VuePagoPlux,
    VuePagoPluxAR,
    VueDataFast,
    BankAccount1Ec,
    VueMp
  },





    props:{

      customer_prop: {
          type: Object
       },


        ticketsTable: {
          type: Object
       },
       subtotalProp: {
          type: Number
       },
       totalProp: {
          type: Number
       },
       feeProp: {
          type: Number
       },
       posProp: {
          type: Number
       },

       first_name: {
          type: String
       },

       last_name: {
          type: String
       },
       event:{
         type: Object
       },
       email_prop: {
          type: String
       },
       phone_prop:{
         type: String
       },

       code:{
         required: false
       },


    
     },

     

     data(){
     return{
       email: "",
       cc:"",
       next:null,
       availableTickets:[],
       order:[],
       password: "",
       customer_id: 0,
       require_account: false,
       debounceFn: null,
       initialSearch: false,
       invoice: 0,
       payment_case: false,
       bankAccount: false,
       posAccount: false,
       value: 'fomoaut',
       url_number: 'fomoaut',
       comment:'fomoaut',
       p_status: 1,
       payment_type: 3,
       final_file:null,
       generated_invoice: {},
       generated_tickets: [],
       codeF: "",
       ticketsApp: false,
       loadingMain: false,
       automatic_sale: false, 
       phone: "",
       cryptoAccount: false,
       showbank: false,
       showbank1ec: false,
       showcash: false,
       showpos: false,
       nuvei_reference: "",
       nuveiAccount: false,
       proc: 1, //type of sale if is nromal of has a promo,
       newTotal: 0,
       newFee: 0,
       showMethods: true,
       f_phone: "",
       f_address: "",
       showPagoPlux: false,
       showPaypal: false,
       appSale: false,
       pos: 0,
      total:0.00,
      fee: 0.00,
      subtotal:0,
      bankAccount1Ec: false,
      reload: true,
      bank_fee: 0,
      revenue_fee:0,
      pname: "",
      isReady: true,
      ticketsM: [],
      indexProm: 0,
      showMpAr: false,
      mp_reference: "",
      currency: "USD",
      showPagoPluxAR: false,
      conversion_rate: false,
      showPaypalAR: false,
      totalAR: 0
      


      
     }
     

   },

    created () {


      this.appSale=this.$route.query.mobile

      if (this.appSale==undefined || this.appSale==null){
        this.appSale=false
      }


      if (this.appSale==false || this.appSale==false){

      if (this.customer_prop.contact_profile.address==""|| this.customer_prop.contact_profile.address==null || this.customer_prop.contact_profile.address==undefined){
        this.f_address="Av 12 de Octubre y Coruña"
      }else{
        this.f_address=this.customer_prop.contact_profile.address
      }

      if (this.customer_prop.contact_profile.phone==""|| this.customer_prop.contact_profile.phone==null || this.customer_prop.contact_profile.phone==undefined){
        this.f_phone="0997668666"
      }else{
        this.f_phone=this.customer_prop.contact_profile.phone
      }

      this.subtotal=this.subtotalProp
      this.total=this.totalProp
      this.fee=this.feeProp
      this.pos=this.posProp

      this.automaticSale()

   

    }else{

      this.appSaleMobile()
    }
    



      this.showBankButton()
      this.showCashButton()
      this.showPosButton()
      this.showPagoPluxButton()

  },


  
   methods:{


    reloadCasePp(rel){
      this.reload=rel

    },


    specialCal1Ec(){
      let coordinated_service=parseFloat(this.event.coordinated_service)

      this.newFee=(coordinated_service * this.ticketsTable.length)
      let subtotal1=parseFloat(this.subtotal) 
      this.newTotal=(subtotal1+this.newFee)
      this.newTotal=this.newTotal.toFixed(2)
      this.newFee=this.newFee.toFixed(2)
      this.proc=3
    },



    specialCal(){
      
      if (this.event.id==106 || this.event.id==108 || this.event.id==105){

        let coordinated_service=parseFloat(this.event.coordinated_service)


        this.newFee=(coordinated_service * this.ticketsTable.length)

      }else{
        this.newFee=parseFloat(0) 

      }
      let subtotal1=parseFloat(this.subtotal) 
      this.newTotal=(subtotal1+this.newFee)
      this.newTotal=this.newTotal.toFixed(2)
      this.newFee=this.newFee.toFixed(2)
      this.proc=3

    },

    returnFromBank(){
      this.bankAccount=false
      this.reload=false
      this.proc=1
      this.showMethods=true

    },


    returnFromBank1Ec(){
      this.reload=false
      this.bankAccount1Ec=false
      this.proc=1
      this.showMethods=true

    },


    returnFromNuvei(){
      this.nuveiAccount=false
      this.proc=1
      this.showMethods=true

    },


    returnFromPos(){
      this.posAccount=false
      this.proc=1
      this.showMethods=true


    },


    nuveiCase(){
      this.showMethods=false


    this.payment_options=false //Variable que significa que carga

  let endpoint = `/api/nuvei/generate-reference/?id=${this.customer_id}&amount=${this.total}`; //open pos
apiService(endpoint).then(data => {

  this.nuvei_reference=data.reference
  this.nuveiAccount=true
});




    },

         showBankButton(){
          let payments = this.event.payment_options

          for (let index = 0; index < payments.length; index++) {
            
            if (payments[index].id==2){
              this.showbank= true

            }else if (payments[index].id==4){
              this.showbank1ec=true

            }
            
          }

          

         },



         showCashButton(){

          const queryString = window.location.search;

          const urlParams = new URLSearchParams(queryString);

          const cash = urlParams.get('cash')

          if(cash=="f0mo1" || cash=="f0mo2" || cash=="f0mo3" || cash=="f0mo4"){
            this.showcash=true
          }
         },


         showPagoPluxButton(){
          let payments = this.event.payment_options

          for (let index = 0; index < payments.length; index++) {
            
            if (payments[index].id==3 || payments[index].id==5){
              this.showPagoPlux= true
              this.pname=payments[index].name
            }

            
            if (payments[index].id==1){
              this.showPaypal= true

            }

            if (payments[index].id==6){
              
    this.payment_options=false //Variable que significa que carga

let endpoint = `/api/mercadopago/generate-reference/?amount=${this.total}&event_id=${this.event.id}`; //open pos


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();


today = yyyy + '-' + mm + '-' + dd;



let ifee = this.fee
let itotal=this.total


if (this.proc==3){
          ifee = this.newFee
         itotal=this.newTotal


}
this.ticketsM=this.ticketsTable

            
            apiService(endpoint, "POST", {
            invoice_items: this.ticketsM,
            invoice_payments: [],
            invoice_payment_terms: [],
            status: 3, //created
           // discount: this.discount,
            discount: 0,
            starting_date: today,
            customer: this.customer_id,
            pos: this.pos,
            subtotal:  this.subtotal,
            taxes : 0,
            total: itotal,
            fee : ifee
            }).then(data => {

this.mp_reference=data.mp.id
this.invoice=data.invoice_id
  this.ticketsM=data.invoice_items
this.showMpAr= true


});




            }
            if (payments[index].id==7){
            
              const url = 'https://api.exchangerate-api.com/v4/latest/USD';

fetch(url)
  .then(response => response.json())
  .then(data => {
    const exchangeRate = data.rates.ARS;
    this.conversion_rate=exchangeRate
      this.showPagoPluxAR=true
      this.currency="AR"
      this.totalAR=(this.total*this.conversion_rate).toFixed(2)
      
      this.showPaypalAR=true

      this.pname=payments[index].name
  })
  .catch(error => console.error('Error al acceder a la API:', error));

            }
            
          }

         },


         showPosButton(){
          const queryString = window.location.search;

          const urlParams = new URLSearchParams(queryString);

          const pos = urlParams.get('pos')

          if(pos=="f0mo1" || pos=="f0mo2" || pos=="f0mo3" || pos=="f0mo4"){
            this.showpos=true
          }

         },


startSaleTwoStep(validator, id, authorization_code, type){
let dataF=0
if(type==5){//  MP Argentina
   this.bank_fee= (parseFloat(this.total)*0.06)
   this.currency="AR"
   
}
if (type==1){
   dataF=(parseFloat(this.total)*0.005)
   this.bank_fee= (parseFloat(this.total)*0.0225)+dataF + (dataF*0.12)
}else if(type==2){
  dataF=(parseFloat(this.total)*0.015)
   this.bank_fee= (parseFloat(this.total)*0.0448)+ dataF + (dataF*0.12)

}else{
  this.bank_fee=0
}

this.url_number=id
this.value=authorization_code


this.ticketsApp=true
this.loadingMain=true

},




         automaticSale(){

           this.customer_id=this.customer_prop.id

           //corregir
           if(this.event.id==16){
            this.customer_id=1

           }


           if (this.subtotal==0.00 || this.subtotal=="0.00" || this.subtotal==0){
             this.automatic_sale=true

              this.startSale(true)
           }else{
            //lo que se hace en pasarelas de pago
            this.getNuveiReference()
           }

         },





appSaleMobile(){


  if (this.appSale!=null || this.appSale!=undefined)


  this.customer_id=this.$route.query.customer_id,
  this.pos=this.$route.query.pos,
  this.subtotal=this.$route.query.subtotal
  this.total=this.$route.query.total
  this.fee=this.$route.query.fee




  //Get 

},




         generateReport (i) {
           console.log(i)
            this.codeF="MEEE"
            console.log("beforePDF")
            console.log(this.codeF)
            
            this.$refs.html2Pdf.generatePdf()
            
        },

        hasDownloaded($event){
            console.log("meee")
            console.log($event)

            this.final_file = new File([$event], 'ticket.pdf');
            this.createPayment()
        },


getNuveiReference(){
let endpoint = `/api/nuvei/generate-reference/?id=${this.customer_id}&amount=${this.total}`; //open pos
apiService(endpoint).then(data => {
  console.log(data)
});

},

  
    getCustomer(){
      let endpoint = `/api/contacts/?email=${this.email}`; //open pos


      console.log(endpoint)

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
                
      apiService(endpoint).then(data => {
        if (data.length==0){
          this.require_account=true
        
        }else{
          this.customer_id = data[0].id;
          this.require_account=false
        }
        this.initialSearch=true
      });
    
    }, 1250)
  },


   getAutoCustomer(){
      let endpoint = `/api/contacts/?email=${this.email}`; //open pos
      this.loadingMain=true
      this.automatic_sale=true

        apiService(endpoint).then(data => {
        if (data.length==0){
          console.log("llegue caso1")
          this.require_account=true
          this.startSale(true)

        
        }else{
          console.log("llegue caso2")
          console.log(data[0].id)
          console.log(".............")
          this.customer_id = data[0].id;
          this.require_account=false
          this.startSale(true)
        }
      });
  },



    
   createInvoice(){
    this.success = false
    this.loading= true



   

    let endpoint="/api/guest-invoices/"


            var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();


today = yyyy + '-' + mm + '-' + dd;



let ifee = this.fee
let itotal=this.total


if (this.proc==3){
          ifee = this.newFee
         itotal=this.newTotal


}
            
            apiService(endpoint, "POST", {
            invoice_items: this.ticketsM,
            invoice_payments: [],
            invoice_payment_terms: [],
            status: 1,
           // discount: this.discount,
            discount: 0,
            starting_date: today,
            customer: this.customer_id,
            pos: this.pos,
            subtotal:  this.subtotal,
            taxes : 0,
            total: itotal,
            fee : ifee

            

        
          })
            .then(data => {
              console.log(data)
                if (data.id){
                 this.invoice=data.id
                 this.generated_invoice=data
                 this.generated_tickets=data.invoice_items


                    this.ticketsApp=true //cambiar eso luego si se require
                    //if ( this.payment_type==3){
                      //this.ticketsApp=true
                    //}else{this.  
                    //creaate payment()

                    //}
                   

                   

                 
                   
                 
                 //this.addPayment()
                
                          }else{
                              alert("Error al crear la factura. Por favor comunícate con nosostros para revisar el caso.")
                              this.error="Verifique que los datos sean correctos"
                          }
        
                
                       
                      })
  
                    



            },

   startSale(validator){

    this.payment_case=validator
    this.loadingMain=true

    if (this.automatic_sale){
      this.isReady=false
      let tes=this.indexProm
      if (tes+1==this.ticketsTable.length){
          this.isReady=true;
        }
        this.ticketsM=[this.ticketsTable[this.indexProm]]
         this.createInvoice() 
      
      
    }else{
      this.ticketsM=this.ticketsTable

      this.createInvoice()


    }


    

  },




startSaleBankCase(file){
data.append('file', file.files[0])
 this.payment_case=true
 this.payment_type=2
 this.startSale(true)

},




  
  startSalePaypalCase(tran_id){
    this.payment_case=true
    this.url_number=tran_id
    this.bank_fee=(parseFloat(this.total)*0.054)+0.30
    this.bank_fee=this.bank_fee.toFixed(2)
    this.startSale(true)

  },


  startSalePaypalCaseAR(tran_id){
    this.payment_case=true
    this.url_number=tran_id
    this.bank_fee=(parseFloat(this.total)*0.06)
    this.bank_fee=this.bank_fee.toFixed(2)
    this.url_number="ARS"
    this.value="ARS"

this.ticketsApp=true
this.loadingMain=true
  },


  
    
  startSalePosCase(authorization_code){
    this.showMethods=false

 this.payment_case=true
 this.url_number=authorization_code
 this.payment_type=5
 this.bank_fee= (parseFloat(this.total)*0.0448)
 this.startSale(true)

},



  startSaleNuvei(validator, id, authorization_code){


    console.log("llegue a nuvei")

 this.payment_case=validator

 this.nuveiAccount=false

 this.value=id
 this.url_number=authorization_code

 this.loadingMain=true


 this.startSale(true)

},


startSalePagoPlux(validator, id, authorization_code, type){


console.log("llegue a PagoPlux")
this.payment_case=validator
this.value=id
this.url_number=authorization_code
this.loadingMain=true
let dataF=0

if (type==1){
   dataF=(parseFloat(this.total)*0.005)
   this.bank_fee= (parseFloat(this.total)*0.0225)+dataF + (dataF*0.12)
}else if(type==2){
  dataF=(parseFloat(this.total)*0.015)
   this.bank_fee= (parseFloat(this.total)*0.0448)+ dataF + (dataF*0.12)

}else if(type==5){//  MP Argentina
  dataF=(parseFloat(this.total)*0.015)
   this.bank_fee= (parseFloat(this.total)*0.0448)+ dataF + (dataF*0.12)
   this.currency="AR"
}else{
  this.bank_fee=0
}
this.startSale(true)
},








  CashCase(){

    



 if (confirm('Confirmar Pago en Efectivo')) {
  this.payment_case=true
 this.payment_type=1
 this.startSale(true)
} else {
  // Do nothing!
  console.log('Thing was not saved to the database.');
}

},



  bankAccountCase(){
    this.showMethods=false
    this.bankAccount=true
  },

  bankAccountCase1Ec(){
    this.showMethods=false
    this.bankAccount1Ec=true
  },


  posAccountCase(){
    this.showMethods=true

    this.posAccount=true
  },



  cryptoCase(){
    this.showMethods=false

    this.cryptoAccount=true
  },


     
   createCustomer(){
    this.loadingMain=true
    this.success = false
    var profile = new Object();
    profile.phone =  this.phone
    profile.address = ""
    profile.city = ""
    if (this.password==""){
      this.password="fomoplankk9876"

    }
    if(this.email!=""){
        let endpoint =  `/api/user/create`
       
    apiService(endpoint, "POST", {
  
    password: this.password,
    contact_profile: profile,
    first_name: this.first_name,
    last_name: this.last_name,
    email: this.email,
    username: this.email

  })
              .then(data => {
                  if (data.id){
                    console.log(data)

                   this.customer_id=data.id
                   this.createInvoice()
        
                  }else{
                      alert("Error al crear el cliente. Por favor comunícate con nosostros para revisar el caso.")
                      this.error="Verifique que los datos sean correctos"
                  }

        
               
              })

              

    

      }else {
        this.error="Llene todos los campos"
      }
    },

afterPdf(file){
  this.final_file=file;
  console.log(this.file)
  this.createPayment()


},


print() {


  let orders = []

  for (let index = 0; index < this.ticketsTable.length; index++) {
    orders.push( this.ticketsTable[index].name)
    
  }
    
    var yourDOCTYPE = "<!DOCTYPE html>"; 
    var printPreview = window.open('', 'print_preview');
    var printDocument = printPreview.document;
    printDocument.open();
    var head =
        "<head>" +
        "<style> .to-print{height:279mm; width:80mm; } </style>" +
        "</head>";
    printDocument.write(yourDOCTYPE +
            "<html>" +
            head +
            "<body>" +
             "<div class='to-print'>" +
             "<p>FOMOPLANES</p>"+
             "<p>Pedido:</p>"+
             "<li>"+ orders + "</li>" +
            "<p>.</p>"+
             "<p>.</p>"+
            "<p>.</p>"+
             "<p>.</p>"+
              "<p>.</p>"+
             "</div>"+
            "</body>" +
            "</html>");
    printPreview.print();
    printPreview.close()
    
 

  window.location.replace("https://www.fomoplanes.com/event/caja-fomo1/?wanted=true")

},


  continueSale(){
      this.$emit('messageFromCustomerData', this.name, this.last_name, this.phone, this.email, this.cc)
    },
 
    createPayment() {



      this.loading=true
        let  endpoint =  `/api/guest-p/?tran_id=fomoaut`
        
        data.append('invoice', this.invoice)
        data.append('point_of_sale', this.pos)
        data.append('url', this.value)
        data.append('url_number', this.url_number)
        data.append('comment', this.comment)
        data.append('subtotal', this.subtotal)
        data.append('currency', this.currency)

        if (this.proc==3){
          data.append('fee', this.newFee)
          data.append('amount', this.newTotal)


        }else{
          data.append('fee', this.fee)
          data.append('amount', this.total)

        }
       
        if (this.payment_type==2){
        this.p_status=0
        this.revenue_fee=this.fee
        }else{
          this.revenue_fee=parseFloat(this.fee)-parseFloat(this.bank_fee)
          this.revenue_fee=this.revenue_fee.toFixed(2)
        }

        if(this.event.id==119  || this.event.id==127){
            this.p_status=0
            this.payment_type=2
           }

           this.bank_fee=parseFloat(this.bank_fee).toFixed(2)


           data.append('bank_fee', this.bank_fee)
           data.append('revenue_fee', this.revenue_fee)

        
        data.append('ticket', this.final_file)
        data.append('payment_type', this.payment_type)
        data.append('status', this.p_status)
        
        apiServiceForm(endpoint, "POST", data)
              .then(data => {
                this.loadingMain=false
                console.log("pago creado")
                console.log(data)


          let tck = data.ticket

        if (this.event.id!=17){// decia 16
          
          if(this.isReady){
          this.$emit('messageFromCustomerData',this.payment_type, tck)
        }else{
          this.indexProm=this.indexProm+1
          this.ticketsApp=false
          this.startSale(true)
        }
        
      }


    
              }).catch(error => {

          alert("Error al ingresar el pago. Por favor comunícate con nosotros para revisar el caso.")
          console.log("error pago:")
          console.log(error)
          //alert('Ha ocurrido un ERROR al actualizar el perfil. Inténtelo nuevamente.  SUDEN - CURSOS CON BOLSA DE EMPLEO')
          //window.location.href = "https://www.somossuden.com/inicio/student-registration.html";


        })
        
       //Ver si el usuario tiene cursos adicionales...
      // if (this.event.id==16){
      //  this.print()

        
      // }

       //Cambiar contraseña y estado del usuario a activado...


      },

       getCode(index, invoice){
        return invoice + "hash" + index;
    },



    





      






    
    
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}

.container{
  justify-content:center
}


  
</style>



<style lang="css">
h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
}


.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198,197,197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption, .blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3C4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}


 .d-inline .input {
    border: 0;
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem;
  }

.quantity-toggle .button {
    background: rgb(198,197,197);
    font-size: 1rem;
    cursor: pointer;
    color: white;
  }


.cont{
   padding-right: 80px;
   padding-left: 80px;
}
.rw{
   padding-right: 90px;
   padding-left: 90px;
  border-top: 1px solid white;

}

.fmbt{ 
  margin-left: 107px; 
  padding-right: 107px;
  margin-bottom: -25px;

  
}
@media (max-width: 768px) {
.cont {
    padding-left: 10px;
    padding-right: 10px;
}

.rw{
   padding-right: 10px;
   padding-left: 10px;


}


.fmbt{ 
  margin-left: 0px; 
  padding-right: 0px;
  margin-bottom: -35px;
  
}

.btn-bank{
  font-size: 13px;
}


}


    
</style>

   